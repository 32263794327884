import { rollTypes } from "../rollTypes";
export const lightning = [
  {
    name: "You call that a crit?",
    description: "Roll damage as normal.",
    rollType: null,
  },
  {
    name: "Shocking!",
    description:
      "Roll damage as normal and the creature cannot use reactions until the end of its next turn.",
    rollType: null,
  },
  {
    name: "Good hit!",
    description:
      "Do not roll your damage dice, instead deal the maximum result possible with those dice.",
    rollType: null,
  },
  {
    name: "Sparks fly!",
    description:
      "Roll your damage as normal and you may choose one other creature within 15 ft. of the victim. That creature must succeed on a Dexterity saving throw (DC 14) or take half as much damage.",
    rollType: null,
  },
  {
    name: "Great hit!",
    description: "Roll twice as many damage dice as normal.",
    rollType: null,
  },
  {
    name: "Electric arc!",
    description:
      "Roll twice as many damage dice as normal and you may choose one other creature within 15 ft. of the victim. That creature must succeed on a Dexterity saving throw (DC 18) or take half as much damage.",
    rollType: null,
  },
  {
    name: "Fulminate!",
    description:
      "Roll twice as many damage dice as normal and roll on the minor injury chart. If the creature is wearing metal armor roll on the major injury chart instead.",
    rollType: [rollTypes.majorInjury, rollTypes.minorInjury],
    condition: { label: "Is the creature wearing metal?", yesInd: 0, noInd: 1 },
  },
  {
    name: "Lit up!",
    description:
      "Deal the maximum amount of damage from your normal damage dice then roll your damage dice and add that result. The creature and each creature you choose within 15 ft. of it cannot take reactions until the end of their next turn. Then roll on the minor injury chart.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Electrocuted!",
    description:
      "Deal twice the maximum result of your damage dice and roll on the major injury chart.",
    rollType: [rollTypes.majorInjury],
  },
  {
    name: "Lightning rod!",
    description:
      "Deal twice the maximum result of your damage dice and you may choose one other creature within 15 ft. of the victim. That creature must succeed on a Dexterity saving throw (DC 20) or take half as much damage. Then roll on the major injury chart.",
    rollType: [rollTypes.majorInjury],
  },
];
