import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  Stack,
  Slider,
  Input,
  IconButton,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Fab,
} from "@mui/material";
import React, { useState } from "react";
import {
  CritEffects,
  getEffectFromType,
  getIndexFromRoll,
  getInjuryFromRoll,
} from "../consts/criteffects";
import { rollTypes } from "../consts/criteffects/rollTypes";
import { insanity } from "../consts/criteffects/insanity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiceD20 } from "@fortawesome/free-solid-svg-icons";

export const RevisedCritCard = ({ addCard }) => {
  const [rollValue, setRollValue] = useState(0);
  const [rollType, setRollType] = useState("physical");
  const [rollSubType, setRollSubtype] = useState("bludgeoning");
  const handleSliderChange = (event, newValue) => {
    setRollValue(newValue);
  };
  const handleInputChange = (event) => {
    setRollValue(event.target.value === "" ? "" : Number(event.target.value));
  };
  const handleBlur = () => {
    if (rollValue < 0) {
      setRollValue(0);
    } else if (rollValue > 20) {
      setRollValue(20);
    }
  };
  const getEffectFromRoll = (rollVal) => {
    const rollTable = getEffectFromType(rollType, rollSubType);

    return rollTable[getIndexFromRoll(rollVal)];
  };
  const generateCardData = (critEffect) => {
    let cardData = {
      header: critEffect.name,
      content: critEffect.description,
      footer: null,
      type: "Critical",
    };
    let effects = [];
    if (critEffect.rollType !== null) {
      effects = critEffect.rollType.map((eachRollType) => {
        let secondaryRollVal = Math.floor(Math.random() * 20 + 1);
        switch (eachRollType) {
          case rollTypes.insanity:
            return insanity[secondaryRollVal];
          default:
            return getInjuryFromRoll(eachRollType, secondaryRollVal);
        }
      });
      cardData.footer = {
        condition: critEffect.rollType.condition
          ? critEffect.rollType.condition
          : null,
        effects: effects,
      };
    }
    return cardData;
  };
  const handleClick = () => {
    const tRoll =
      rollValue < 1 ? Math.floor(Math.random() * 20 + 1) : rollValue;

    const critEffect = getEffectFromRoll(tRoll);
    const cardData = generateCardData(critEffect);
    console.log(cardData);
    addCard(cardData);
  };
  return (
    <Card variant="outlined" style={{ minWidth: "360px" }}>
      <CardHeader title={"Revised Critical"} />
      <CardContent>
        <Box sx={{ width: "200px" }}>
          <Typography id="input-slider" gutterBottom>
            Manual Roll Entry
          </Typography>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <Slider
              value={typeof rollValue === "number" ? rollValue : 0}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
              min={0}
              max={20}
              step={1}
            />
            <Input
              value={rollValue}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: 0,
                max: 20,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Stack>
        </Box>
      </CardContent>
      <CardActions spacing={0}>
        <ToggleButtonGroup
          exclusive
          value={rollType}
          onChange={(evt, al) => al && setRollType(al)}
          size="small"
        >
          <ToggleButton value="physical">Physical</ToggleButton>
          <ToggleButton value="magic">Magical</ToggleButton>
        </ToggleButtonGroup>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Damage Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={rollSubType}
            label="Damage Type"
            onChange={(evt) => setRollSubtype(evt.target.value)}
            style={{ minWidth: "10em" }}
          >
            {Object.keys(CritEffects[rollType]).map((eachSubtype) => {
              const itemLabel =
                eachSubtype.charAt(0).toUpperCase() + eachSubtype.slice(1);
              return (
                <MenuItem value={eachSubtype} key={eachSubtype}>
                  {itemLabel}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <IconButton onClick={handleClick}>
          <FontAwesomeIcon icon={faDiceD20} />
        </IconButton>
      </CardActions>
    </Card>
  );
};
