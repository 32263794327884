import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Typography,
  Container,
} from "@mui/material";
export const EffectCard = ({ cardData }) => {
  const getFooter = (footer) => {
    if (footer && !footer.condition) {
      return footer.effects.map((effect) => {
        return (
          <Container>
            <Typography>
              {effect.name} - {effect.description}
            </Typography>
          </Container>
        );
      });
    }
    return <></>;
  };
  return (
    <Card>
      <CardHeader title={cardData.header} subheader={cardData.type} />
      <CardContent>
        <Typography variant="body1">{cardData.content}</Typography>
      </CardContent>
      <CardActionArea>{getFooter(cardData.footer)}</CardActionArea>
    </Card>
  );
};
