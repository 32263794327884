import { rollTypes } from "../rollTypes";
export const psychic = [
  {
    name: "You call that a crit?",
    description: "Roll damage as normal.",
    rollType: null,
  },
  {
    name: "Disoriented!",
    description:
      "Roll your damage dice as normal and you control the creature’s movement on its next turn.",
    rollType: null,
  },
  {
    name: "Confused!",
    description:
      "Roll your damage dice as normal and the creature cannot differentiate friend from foe until the end of its next turn.",
    rollType: null,
  },
  {
    name: "Good hit!",
    description:
      "Do not roll your damage dice, instead deal the maximum result possible with those dice.",
    rollType: null,
  },
  {
    name: "Great hit!",
    description: "Roll twice as many damage dice as normal.",
    rollType: null,
  },
  {
    name: "Dominated!",
    description:
      "Roll twice as many damage dice as normal and you control the creature’s action on its next turn.",
    rollType: null,
  },
  {
    name: "Psychological fracture!",
    description:
      "Roll twice as many damage dice as normal and roll on the Insanity chart with disadvantage.",
    rollType: rollTypes.insanity,
  },
  {
    name: "Psychological break!",
    description:
      "Deal the maximum amount of damage from your normal damage dice then roll your damage dice and add that result. Then roll on the Insanity chart.",
    rollType: rollTypes.insanity,
  },
  {
    name: "Madness!",
    description:
      "Deal twice the maximum result of your damage dice and roll on the Insanity chart.",
    rollType: rollTypes.insanity,
  },
  {
    name: "Mind melt!",
    description:
      "Deal twice the maximum result of your damage dice and roll on the Insanity chart with advantage",
    rollType: rollTypes.insanity,
  },
];
