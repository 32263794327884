import { rollTypes } from "../rollTypes";
export const slashing = [
  {
    name: "You call that a crit?",
    description: "Roll damage as normal.",
    rollType: null,
  },
  {
    name: "Sliced and diced!",
    description:
      "Roll damage as normal and the creature loses 1d6 hit points at the start of its next turn.",
    rollType: null,
  },
  {
    name: "Good hit!",
    description:
      "Do not roll your damage dice, instead deal the maximum result possible with those dice.",
    rollType: null,
  },
  {
    name: "Open gash!",
    description:
      "Roll your damage dice as normal and the creature is bleeding. For the next minute the creature loses 1d4 damage at the start of each of its turns until it uses an action to staunch this wound.",
    rollType: null,
  },
  {
    name: "Great hit!",
    description: "Roll your damage dice twice and add them together",
    rollType: null,
  },
  {
    name: "Deep slice!",
    description:
      "Roll your damage dice twice and add them together and the creature is bleeding. For the next minute the creature loses 1d8 hit points at the start of each of its turns until it uses an action to staunch this wound.",
    rollType: null,
  },
  {
    name: "Lacerated!",
    description:
      "Roll your damage dice twice and add them together and the creature is bleeding. For the next minute the creature loses 1d12 hit points at the start of each of its turns until it uses an action to staunch this wound.",
    rollType: null,
  },
  {
    name: "Severed!",
    description:
      "Deal the maximum amount of damage from your normal damage dice then roll your damage dice and add the result. Then roll on the Minor Injury chart. If the creature is wearing light or no armor roll on the Major Injury chart instead.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Dissected!",
    description:
      "Deal twice the maximum result of your damage dice and roll on the major injury chart.",
    rollType: [rollTypes.majorInjury],
  },
  {
    name: "Slash!",
    description:
      "Deal the maximum result of your damage dice twice, roll on the major injury chart, and the creature is bleeding. For the next minute the creature loses 2d8 hit points at the start of each of its turns until it uses an action to staunch this wound",
    rollType: [rollTypes.majorInjury],
  },
];
