import { rollTypes } from "../rollTypes";

export const radiant = [
  {
    name: "You call that a crit?",
    description: "Roll damage as normal.",
    rollType: null,
  },
  {
    name: "Dazzled!",
    description:
      "Roll damage as normal and the creature cannot willingly move closer to you until the end of its next turn.",
    rollType: null,
  },
  {
    name: "Good hit!",
    description:
      "Do not roll your damage dice, instead deal the maximum result possible with those dice.",
    rollType: null,
  },
  {
    name: "Disheartening blast!",
    description:
      "Roll your damage as normal and the creature is frightened until the end of its next turn.",
    rollType: null,
  },
  {
    name: "Great hit!",
    description: "Roll twice as many damage dice as normal.",
    rollType: null,
  },
  {
    name: "Awed!",
    description:
      "Roll twice as many damage dice as normal and the creature is frightened until the end of its next turn.",
    rollType: null,
  },
  {
    name: "Holy terror!",
    description:
      "Roll twice as many damage dice as normal and roll on the minor injury chart. Additionally, the creature is frightened for the next minute. It can make a Wisdom saving throw (DC 16) at the end of each of its turns to end this effect.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Righteous mark!",
    description:
      "Deal the maximum amount of damage for your normal damage dice then roll your damage dice and add that result, then roll on the minor injury chart. Additionally, the creature glows for the next minute. While glowing it produces bright light up 10 feet and dim light up to 30 feet and all successful attacks against the creature deal an additional 1d4 radiant damage.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Wrath of the gods!",
    description:
      "Deal twice the maximum result of your damage dice and roll on the major injury chart.",
    rollType: [rollTypes.majorInjury],
  },
  {
    name: "Smote!",
    description:
      "Deal twice the maximum result of your damage dice and roll on the major injury chart. Additionally, the creature glows for the next minute. While glowing it produces bright light up 10 feet and dim light up to 30 feet and all successful attacks against the creature deal an additional 1d6 radiant damage.",
    rollType: [rollTypes.majorInjury],
  },
];
