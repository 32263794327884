import { rollTypes } from "../rollTypes";
export const fire = [
  {
    name: "You call that a crit?",
    description: "Roll damage as normal.",
    rollType: null,
  },
  {
    name: "Heat wave!",
    description:
      "Roll damage as normal and attack rolls for attacks that deal fire damage have advantage when made against the creature until the end of its next turn.",
    rollType: null,
  },
  {
    name: "Good hit!",
    description:
      "Do not roll your damage dice, instead deal the maximum result possible with those dice.",
    rollType: null,
  },
  {
    name: "Hot flash!",
    description:
      "Roll your damage as normal and the creature is on fire. While the creature is on fire it takes 2d4 fire damage at the start of each of its turns. The creature can end this condition by dropping prone and using 5 feet of movement to roll on the ground.",
    rollType: null,
  },
  {
    name: "Great hit!",
    description: "Roll twice as many damage dice as normal.",
    rollType: null,
  },
  {
    name: "Ablaze!",
    description:
      "Roll twice as many damage dice as normal and the creature is on fire. While the creature is on fire it takes 2d6 fire damage at the start of each of its turns. The creature can end this condition by dropping prone and using 5 feet of movement to roll on the ground.",
    rollType: null,
  },
  {
    name: "Burnt to a crisp!",
    description:
      "Roll twice as many damage dice as normal and the creature is charred. If the creature has resistance to fire, it loses that resistance. If the creature does not have resistance to fire, it gains vulnerability to fire. Both of these effects can be ended the same as a minor injury.",
    rollType: null,
  },
  {
    name: "Hellfire!",
    description:
      "Deal the maximum amount of damage from your normal damage dice then roll your damage dice and add that result then roll on the minor injury chart. Additionally, the creature is on fire. While the creature is on fire it takes 2d6 fire damage at the start of each of its turns. The creature can end this condition by dropping prone and using 5 feet of movement to roll on the ground.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Combustion!",
    description:
      "Deal twice the maximum result of your damage dice and roll on the major injury chart.",
    rollType: [rollTypes.majorInjury],
  },
  {
    name: "Inferno!",
    description:
      "Deal twice the maximum result of your damage dice and roll on the major injury chart. Additionally, the creature is on fire. While the creature is on fire it takes 2d8 fire damage at the start of each of its turns. The creature can end this condition by dropping prone and using 5 feet of movement to roll on the ground.",
    rollType: [rollTypes.majorInjury],
  },
];
