import { rollTypes } from "../rollTypes";
export const necrotic = [
  {
    name: "You call that a crit?",
    description: "Roll damage as normal.",
    rollType: null,
  },
  {
    name: "Spoil!",
    description:
      "Roll damage as normal and the creature cannot regain hit points until the end of its next turn.",
    rollType: null,
  },
  {
    name: "Good hit!",
    description:
      "Do not roll your damage dice, instead deal the maximum result possible with those dice.",
    rollType: null,
  },
  {
    name: "Fester!",
    description:
      "Roll your damage as normal and the creature’s maximum hit points are reduced by the same amount.",
    rollType: null,
  },
  {
    name: "Great hit!",
    description: "Roll twice as many damage dice as normal.",
    rollType: null,
  },
  {
    name: "Decay!",
    description:
      "Roll twice as many damage dice as normal and the creature’s maximum hit points are reduced by the same amount.",
    rollType: null,
  },
  {
    name: "Rot!",
    description:
      "Roll twice as many damage dice as normal and the creature cannot regain hit points for the next minute. It may make a saving throw (DC 16) at the end of each of its turns to end this effect.",
    rollType: null,
  },
  {
    name: "Blight!",
    description:
      "Deal the maximum amount of damage from your normal damage dice then roll your damage dice and add that result. The creature’s maximum hit points are reduced by the same amount. Then roll on the minor injury chart.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Atrophy!",
    description:
      "Deal twice the maximum result of your damage dice and roll on the major injury chart.",
    rollType: [rollTypes.majorInjury],
  },
  {
    name: "Putrefy!",
    description:
      "Deal twice the maximum result of your damage dice, the creature’s maximum hit points are reduced by the same amount, and the creature cannot regain hit points until the end of its next turn. Then roll on the major injury chart.",
    rollType: [rollTypes.majorInjury],
  },
];
