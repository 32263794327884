import { Divider, Grid, Box } from "@mui/material";
import React, { useState } from "react";
import { EffectCard } from "./EffectCard";
import { RevisedCritCard } from "./RevisedCritCard";
import { StressAfflictionCard } from "./StressAfflictionCard";
import { Masonry } from "@mui/lab";
export const EffectGenerator = () => {
  const [effectCards, setEffectCards] = useState([]);

  const addCard = (card) => {
    let tempCards = effectCards;
    tempCards = [card, ...tempCards];
    if (tempCards.length > 11) {
      tempCards = tempCards.slice(0, -1);
    }
    setEffectCards(tempCards);
  };
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      style={{ padding: "12em", paddingTop: "2em" }}
    >
      <Grid item xs={12} container direction="row" alignItems="stretch">
        <Grid item xs={5}>
          <StressAfflictionCard addCard={addCard} />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <RevisedCritCard addCard={addCard} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ lineHeight: "2em" }} />
      </Grid>
      {effectCards.length > 0 && (
        <Grid item xs={12}>
          <Box>
            <EffectCard key={"firstItem"} cardData={effectCards[0]} />
            <Masonry spacing={2}>
              {effectCards.slice(1).map((card, ind) => {
                return (
                  <Box
                    key={card.type + ind}
                    style={{ width: "250px", padding: "20px" }}
                  >
                    <EffectCard cardData={card} />
                  </Box>
                );
              })}
            </Masonry>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
