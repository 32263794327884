import { rollTypes } from "../rollTypes";
export const force = [
  {
    name: "You call that a crit?",
    description: "Roll damage as normal.",
    rollType: null,
  },
  {
    name: "Spellstruck!",
    description:
      "Roll damage as normal and the creature has disadvantage on saving throws against spells until the end of its next turn.",
    rollType: null,
  },
  {
    name: "Good hit!",
    description:
      "Do not roll your damage dice, instead deal the maximum result possible with those dice.",
    rollType: null,
  },
  {
    name: "Eldritch incandescence!",
    description:
      "Roll your damage as normal and spell attack rolls against the creature have advantage until the end of its next turn.",
    rollType: null,
  },
  {
    name: "Great hit!",
    description: "Roll twice as many damage dice as normal.",
    rollType: null,
  },
  {
    name: "Bewitching blow!",
    description:
      "Roll twice as many damage dice as normal and the creature is spellbound until the end of its next turn. While spellbound it makes saving throws against spells with disadvantage and spell attack rolls against it have advantage.",
    rollType: null,
  },
  {
    name: "Mystic magnet!",
    description:
      "Roll twice as many damage dice as normal and the creature is spellbound for the next minute. While spellbound it makes saving throws against spells with disadvantage and spell attack rolls against it have advantage. At the end of each of the creature’s turns it can make an Intelligence saving throw (DC 14) to end this effect.",
    rollType: null,
  },
  {
    name: "Ensorcelled!",
    description:
      "Deal the maximum amount of damage from your normal damage dice then roll your damage dice and add that result then roll on the minor injury chart. Additionally, the creature is spellbound for the next minute. While spellbound it makes saving throws against spells with disadvantage and spell attack rolls against it have advantage. At the end of each of the creature’s turns it can make an Intelligence saving throw (DC 16) to end this effect.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Arcane injury!",
    description:
      "Deal twice the maximum result of your damage dice and roll on the major injury chart.",
    rollType: [rollTypes.majorInjury],
  },
  {
    name: "Magically mauled!",
    description:
      "Deal twice the maximum result of your damage dice and roll on the major injury chart. Additionally, the creature is spellbound for the next minute. While spellbound it makes saving throws against spells with disadvantage and spell attack rolls against it have advantage. At the end of each of the creature’s turns it can make an Intelligence saving throw (DC 18) to end this effect",
    rollType: [rollTypes.majorInjury],
  },
];
