import { rollTypes } from "../rollTypes";
export const poison = [
  {
    name: "You call that a crit?",
    description: "Roll damage as normal.",
    rollType: null,
  },
  {
    name: "Nauseous!",
    description:
      "Roll damage as normal and the creature has disadvantage on its next ability check, attack roll, or saving throw.",
    rollType: null,
  },
  {
    name: "Good hit!",
    description:
      "Do not roll your damage dice, instead deal the maximum result possible with those dice.",
    rollType: null,
  },
  {
    name: "Sickened!",
    description:
      "Roll your damage as normal and the creature has disadvantage on all ability checks, attack rolls, and saving throws until the end of its next turn.",
    rollType: null,
  },
  {
    name: "Great hit!",
    description: "Roll twice as many damage dice as normal.",
    rollType: null,
  },
  {
    name: "Poisoned!",
    description:
      "Roll twice as many damage dice as normal and the creature is poisoned for the next minute. The creature may attempt a saving throw at the end of each of its turns (DC 12) to end this effect.",
    rollType: null,
  },
  {
    name: "Contaminated!",
    description:
      "Roll twice as many damage dice as normal and the creature is poisoned for the next minute. The creature may attempt a saving throw at the end of each of its turns (DC 16) to end this effect.",
    rollType: null,
  },
  {
    name: "Toxic shock!",
    description:
      "Deal the maximum amount of damage from your normal damage dice then roll your damage dice and add that result. Then roll on the minor injury chart and the creature is poisoned for the next minute. The creature may attempt a saving throw at the end of each of its turns (DC 12) to end this effect.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "System failure!",
    description:
      "Deal twice the maximum result of your damage dice and roll on the major injury chart.",
    rollType: [rollTypes.majorInjury],
  },
  {
    name: "Biological breakdown!",
    description:
      "Deal twice the maximum result of your damage dice, roll on the major injury chart, and the creature is poisoned for the next minute. The creature may attempt a saving throw at the end of each of its turns (DC 16) to end this effect.",
    rollType: [rollTypes.majorInjury],
  },
];
