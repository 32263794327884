import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  Stack,
  Slider,
  Input,
  Box,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Afflictions } from "../consts/afflictions";
import { faDiceD20 } from "@fortawesome/free-solid-svg-icons";

export const StressAfflictionCard = ({ addCard }) => {
  const [rollValue, setRollValue] = useState(0);
  const handleSliderChange = (event, newValue) => {
    setRollValue(newValue);
  };
  const handleInputChange = (event) => {
    setRollValue(event.target.value === "" ? "" : Number(event.target.value));
  };
  const handleBlur = () => {
    if (rollValue < 0) {
      setRollValue(0);
    } else if (rollValue > 100) {
      setRollValue(100);
    }
  };
  const getAfflictionEffectVals = (roll) => {
    return Afflictions.filter((el) => el.val <= roll).reduce(
      (prev, current) => {
        return prev.val > current.val ? prev : current;
      }
    );
  };
  const handleRoll = () => {
    const tRollVal =
      rollValue < 1 ? Math.floor(Math.random() * 100 + 1) : rollValue;
    const effectVals = getAfflictionEffectVals(tRollVal);
    addCard({
      header: effectVals.name,
      content: effectVals.effect,
      footer: null,
      type: "Affliction",
    });
  };
  return (
    <Card variant="outlined" style={{minWidth:"231px"}}>
      <CardHeader title={"Stress Afflictions"} />
      <CardContent>
        <Box sx={{ width: "200px" }}>
          <Typography id="input-slider" gutterBottom>
            Manual Roll Entry
          </Typography>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <Slider
              value={typeof rollValue === "number" ? rollValue : 0}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
            />
            <Input
              value={rollValue}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: 0,
                max: 100,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Stack>
        </Box>
      </CardContent>
      <CardActions>
        <IconButton onClick={handleRoll}>
          <FontAwesomeIcon icon={faDiceD20} />
        </IconButton>
      </CardActions>
    </Card>
  );
};
