import { rollTypes } from "../rollTypes";
export const cold = [
  {
    name: "You call that a crit?",
    description: "Roll damage as normal.",
    rollType: null,
  },
  {
    name: "Chills!",
    description:
      "Roll damage as normal and the creature may only move half its movement speed on its next turn.",
    rollType: null,
  },
  {
    name: "Good hit!",
    description:
      "Do not roll your damage dice, instead deal the maximum result possible with those dice.",
    rollType: null,
  },
  {
    name: "Frosty!",
    description:
      "Roll your damage as normal and the creature’s movement speed is 0 until the end of its next turn.",
    rollType: null,
  },
  {
    name: "Great hit!",
    description: "Roll twice as many damage dice as normal.",
    rollType: null,
  },
  {
    name: "Freezing!",
    description:
      "Roll twice as many damage dice as normal and the creature is paralyzed until the end of its next turn.",
    rollType: null,
  },
  {
    name: "Frozen!",
    description:
      "Roll twice as many damage dice as normal and the creature is paralyzed until the end of its next turn. If the creature takes damage before the end of its next turn, roll on the minor injury chart.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Ice block!",
    description:
      "Deal the maximum amount of damage from your normal damage dice then roll your damage dice and add that result. The creature is paralyzed until the end of its next turn and rolls on the minor injury chart.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Glacial!",
    description:
      "Deal twice the maximum result of your damage dice and roll on the major injury chart.",
    rollType: [rollTypes.majorInjury],
  },
  {
    name: "Subzero!",
    description:
      "Deal twice the maximum result of your damage dice, roll on the major injury chart, and the creature is paralyzed for the next minute. The creature may attempt a saving throw at the end of each of its turns (DC 16) to end this effect. If it fails this saving throw three times it is frozen solid and becomes a petrified but frozen solid in a block of ice rather than turned to stone.",
    rollType: [rollTypes.majorInjury],
  },
];
