import { rollTypes } from "../rollTypes";
export const bludgeoning = [
  {
    name: "You call that a crit?",
    description: "Roll damage as normal.",
    rollType: null,
  },
  {
    name: "Smashed off balance!",
    description:
      "Roll damage as normal and the next attack against the creature has advantage.",
    rollType: null,
  },
  {
    name: "Good hit!",
    description:
      "Do not roll your damage dice, instead deal the maximum result possible with those dice.",
    rollType: null,
  },
  {
    name: "Sent reeling!",
    description:
      "Do not roll your damage dice, instead deal the maximum result possible with those dice and push the creature up to 15 feet in any direction.",
    rollType: null,
  },
  {
    name: "Great hit!",
    description: "Roll your damage dice twice and add them together.",
    rollType: null,
  },
  {
    name: "Take a seat!",
    description:
      "Roll damage dice twice and add them together and the creature is knocked prone.",
    rollType: null,
  },
  {
    name: "Rocked and rolled!",
    description:
      "Roll damage dice twice and add them together, push the creature up to 15 feet away, and the creature is knocked prone.",
    rollType: null,
  },
  {
    name: "Grievous injury!",
    description:
      "Deal the maximum amount of damage from your normal damage dice then roll your damage dice and add the result. Then roll on the Minor Injury chart. If the creature is wearing heavy armor roll on the Major Injury chart instead.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Crushed!",
    description:
      "Deal the twice maximum result of your damage dice and roll on the major injury chart.",
    rollType: [rollTypes.majorInjury],
  },
  {
    name: "Splat!",
    description:
      "Deal the maximum result of your damage dice twice, the creature is stunned until the end of your next turn, and roll on the major injury chart.",
    rollType: [rollTypes.majorInjury],
  },
];
