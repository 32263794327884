export const Afflictions = [
    {val:6,name:	"Fearful", effect:	"Disadvantage on WIS checks & saves"},
    {val:12,name:	"Lethargic", effect:	"+1 exhaustion until removed"},
    {val:18,name:	"Masochistic", effect:	"Disadvantage on CON checks & saves"},
    {val:24,name:	"Irrational", effect:	"Disadvantage on INT checks & saves"},
    {val:30,name:	"Paranoid", effect:	"Speed is halved"},
    {val:36,name:	"Selfish", effect:	"Disadvantage on CHA checks & saves"},
    {val:42,name:	"Panic", effect:	"Disadvantage on DEX checks & saves"},
    {val:48,name:	"Hopelessness", effect:	"Disadvantage on STR checks & saves"},
    {val:54,name:	"Mania", effect:	"Disadvantage on attack rolls"},
    {val:60,name:	"Anxiety", effect:	"Disadvantage on Stress checks"},
    {val:66,name:	"Hypochondria", effect:	"Hit point maximum is halved"},
    {val:72,name:	"Narcissistic", effect:	"Disadvantage on ability checks"},
    {val:77,name:	"Powerful", effect:	"+2 to all damage rolls"},
    {val:82,name:	"Focused", effect:	"+2 to all attack rolls"},
    {val:87,name:	"Stalwart", effect:	"+2 AC"},
    {val:91,name:	"Acute", effect:	"Advantage on INT checks & saves"},
    {val:96,name:	"Perceptive", effect:	"Advantage on WIS checks & saves"},
    {val:100,name:	"Courageous", effect:	"Advantage on CHA checks & saves"},
]