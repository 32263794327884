export const insanity = [
  {
    name: "Synesthesia.",
    description:
      "You can hear colors, smell sounds, or taste textures. Regardless of the specific manifestation, you have disadvantage on all Perception and Investigation skill checks.",
  },
  {
    name: "Kleptomania.",
    description:
      "Once per day when you are in a personal residence or marketplace, the DM can call on you to succeed on a Wisdom saving throw (DC 12) or attempt to steal an item of insignificant practical and monetary value.",
  },
  {
    name: "Paranoia.",
    description:
      "Once per day following an interaction with another creature (including other PCs) the DM can call on you to succeed on a Wisdom saving throw (DC 12) or you suspect that creature is secretly plotting against you.",
  },
  {
    name: "Obsession.",
    description:
      "Choose a person or personal interest you are obsessed with. Once per day, when you are presented with an opportunity to interact with or learn more about the subject of your obsession the DM can call on you to succeed on a Wisdom saving throw (DC 14) or ignore everything else to obsess over the object of your fascination.",
  },
  {
    name: "Addiction.",
    description:
      "Choose a behavior or substance you have used. Once per day, when you are presented with an opportunity to do the behavior or use the substance the DM can call on you to succeed on a Wisdom saving throw (DC 14) or ignore everything else to indulge in your vice.",
  },
  {
    name: "Odd Thinking.",
    description:
      "Once per day when you hear a rational explanation for an event or occurrence, your DM may call on you to succeed on a Wisdom saving throw (DC 12) or you reject the rational explanation for a conspiratorial or fantastical explanation.",
  },
  {
    name: "Narcissism.",
    description:
      "When you take an action or series of action that doesn’t directly benefit you, you must pass a Wisdom saving throw (DC 11) or you can’t take that action / series of actions. If any self-sacrifice on your part would be required the DC of the saving throw is increased to 16.",
  },
  {
    name: "Delusional.",
    description:
      "When you gain this insanity the DM will tell you a belief that you have. No matter what evidence is presented to the contrary so long as you have this insanity you cannot be persuaded that this belief is not true.",
  },
  {
    name: "Pica.",
    description:
      "Once per day the DM can call on you to pass a Wisdom saving throw (DC 14) or immediately eat one non-food object (such as dirt, napkins, or a small piece of jewelry) of the DM’s choice.",
  },
  {
    name: "Retrograde amnesia.",
    description:
      "You forget everything about your personal life prior to the moment you received this insanity.",
  },
  {
    name: "Overwhelmed.",
    description:
      "If you do not have immunity or resistance to psychic damage, you gain vulnerability to psychic damage. If you have resistance to psychic damage, you lose it. If you have immunity to psychic damage, you lose it but gain resistance to psychic damage.",
  },
  {
    name: "Anterograde amnesia.",
    description:
      "Whenever you try to recall a fact you learned since you received this insanity, make a Wisdom saving throw (DC 12). If you fail you cannot recall the fact.",
  },
  {
    name: "Dependence.",
    description:
      "You must pass a Wisdom saving throw (DC 14) to take an action that one or more of your allies disapprove of.",
  },
  {
    name: "Anxious.",
    description:
      "You have disadvantage on saving throws against being frightened. Additionally, once per day the DM can call on you to succeed a Wisdom saving throw (DC 14) or be frightened by a creature of the DM’s choosing for the next minute.",
  },
  {
    name: "Mute.",
    description:
      "Whenever you wish to speak allowed (including casting a spell that has verbal components) you must succeed on a Wisdom saving throw (DC 13) to do so.",
  },
  {
    name: "Narcolepsy.",
    description:
      "You have disadvantage on saving throws against sleeping or unconsciousness. Once per day the DM may call on you to succeed on a Constitution saving throw (DC 12) or fall unconscious for one minute or until you take damage or another creature spends their action trying to rouse you.",
  },
  {
    name: "Insomnia.",
    description:
      "You cannot take long rests and your short rests take 8 hours to complete.",
  },
  {
    name: "Homicidal.",
    description:
      "After each long rest you must pass a Wisdom saving throw (DC 14) or be overcome with the urge to end the life of a humanoid creature and you cannot benefit from another long rest until you do so.",
  },
  {
    name: "Suicidal.",
    description:
      "After each long rest you must pass a Wisdom saving throw (DC 12) or make an attempt to end your own life.",
  },
  { name: "Catatonia.", description: "You are unconscious for 10d10 years." },
];
