import { rollTypes } from "../rollTypes";
export const piercing = [
  {
    name: "You call that a crit?",
    description: "Roll damage as normal.",
    rollType: null,
  },
  {
    name: "Lunge and thrust!",
    description: "Roll damage dice twice and use the higher result.",
    rollType: null,
  },
  {
    name: "Good hit!",
    description:
      "Do not roll your damage dice, instead deal the maximum result possible with those dice.",
    rollType: null,
  },
  {
    name: "Stabbed!",
    description: "Roll your damage dice twice and add them together.",
    rollType: null,
  },
  {
    name: "Great hit!",
    description: "Roll your damage dice twice and add them together.",
    rollType: null,
  },
  {
    name: "Swiss cheese!",
    description:
      "Roll twice as many damage dice as normal. Then roll twice on the minor injury chart and use the lower result.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Punctured!",
    description:
      "Roll your damage dice twice and add them together and roll on the minor injury chart.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Cruel prick!",
    description:
      "Roll your damage dice twice and add them together and roll on the major injury chart.",
    rollType: [rollTypes.majorInjury],
  },
  {
    name: "Run through!",
    description:
      "Deal twice the maximum result of your damage dice and roll on the major injury chart.",
    rollType: [rollTypes.majorInjury],
  },
  {
    name: "Pierce!",
    description:
      "Deal the maximum result of your damage dice twice, roll on the minor injury chart, and roll on the major injury chart.",
    rollType: [rollTypes.minorInjury, rollTypes.majorInjury],
  },
];
