import { rollTypes } from "../rollTypes";

export const thunder = [
  {
    name: "You call that a crit?",
    description: "Roll damage as normal.",
    rollType: null,
  },
  {
    name: "Boom!",
    description:
      "Roll damage as normal and the creature is deafened until the end of its next turn.",
    rollType: null,
  },
  {
    name: "Good hit!",
    description:
      "Do not roll your damage dice, instead deal the maximum result possible with those dice.",
    rollType: null,
  },
  {
    name: "Ka-boom!",
    description:
      "Roll your damage as normal and the creature is deafened for one minute.",
    rollType: null,
  },
  {
    name: "Great hit!",
    description: "Roll twice as many damage dice as normal.",
    rollType: null,
  },
  {
    name: "Thunder clap!",
    description:
      "Roll twice as many damage dice as normal and the creature is stunned until the start of its next turn and deafened for one minute.",
    rollType: null,
  },
  {
    name: "Burst ear drums!",
    description:
      "Roll twice as many damage dice as normal and the creature is deafened permanently. Then roll on the minor injury chart.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Concussive blast!",
    description:
      "Deal the maximum amount of damage from your normal damage dice then roll your damage dice and add that result. The creature stunned until the end of its next turn and deafened permanently. Then roll on the minor injury chart.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Wall of sound!",
    description:
      "Deal twice the maximum result of your damage dice and roll on the major injury chart.",
    rollType: [rollTypes.majorInjury],
  },
  {
    name: "Sonic salvo!",
    description:
      "Deal twice the maximum result of your damage dice, the creature is deafened permanently, and stunned until the end of its next round. Then roll on the major injury chart",
    rollType: [rollTypes.majorInjury],
  },
];
