import { rollTypes } from "../rollTypes";
export const acid = [
  {
    name: "You call that a crit?",
    description: "Roll damage as normal.",
    rollType: null,
  },
  {
    name: "Scalding bile!",
    description:
      "Roll damage as normal and the creature is scarred. While scarred the creature has disadvantage on all Charisma ability checks except Charisma (Intimidation). Being scarred can be healed in the same way as a minor injury.",
    rollType: null,
  },
  {
    name: "Good hit!",
    description:
      "Do not roll your damage dice, instead deal the maximum result possible with those dice.",
    rollType: null,
  },
  {
    name: "Melted flesh!",
    description:
      "Roll your damage as normal and the creature is disfigured. While disfigured the creature has disadvantage on all Charisma ability checks except Charisma (Intimidation). Being disfigured can be removed with the spell greater restoration.",
    rollType: null,
  },
  {
    name: "Great hit!",
    description: "Roll your damage dice twice and add them together.",
    rollType: null,
  },
  {
    name: "Boiling flesh!",
    description:
      "Roll twice as many damage dice as normal and if the creature is wearing armor its AC modifier is reduced by 1 until it can be repaired (for 1/4th the price of new armor of the same type) or cleaned (if the armor is magical). If the creature is not wearing armor, roll on the minor injury chart.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Horrific mutilation!",
    description:
      "Roll twice as many damage dice as normal and roll on the minor injury chart. Additionally, the creature is disfigured. While disfigured the creature has disadvantage on all Charisma ability checks except Charisma (Intimidation). Being disfigured can be removed with the spell greater restoration.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Caustic trauma!",
    description:
      "Deal the maximum amount of damage from your normal damage dice then roll your damage dice and add that result. If the creature is wearing armor, roll on the minor injury chart and its AC modifier is reduced by 2 until it can be repaired (for half the price of new armor of the same type) or cleaned (if the armor is magical). If the creature is not wearing armor, roll on the major injury chart.",
    rollType: [rollTypes.minorInjury],
  },
  {
    name: "Vitriolic!",
    description:
      "Deal twice the maximum result of your damage dice and roll on the major injury chart.",
    rollType: [rollTypes.majorInjury],
  },
  {
    name: "Acid bath!",
    description:
      "Deal twice the maximum result of your damage dice. If the creature is wearing armor, the armor is destroyed (if non-magical) or rendered useless until cleaned during a long rest (if magical) and roll on the major injury chart. If the creature is not wearing armor, roll on the major injury chart and the creature is disfigured. While disfigured the creature has disadvantage on all Charisma ability checks except Charisma (Intimidation). Being disfigured can be removed with the spell greater restoration.",
    rollType: [rollTypes.majorInjury],
  },
];
