import { EffectGenerator } from "./components/EffectGenerator";
import { Paper } from "@mui/material";
import WattorArt from "./assets/WattorArt.png";

function App() {
  return (
    <Paper sx={{ backgroundImage: `url(${WattorArt})`, height: "100vh" }}>
      <EffectGenerator />
    </Paper>
  );
}

export default App;
