import { acid } from "./acid";
import { cold } from "./cold";
import { fire } from "./fire";
import { force } from "./force";
import { lightning } from "./lightning";
import { necrotic } from "./necrotic";
import { poison } from "./poison";
import { psychic } from "./psychic";
import { radiant } from "./radiant";
import { thunder } from "./thunder";

export const magic = {
  acid,
  cold,
  fire,
  force,
  lightning,
  necrotic,
  poison,
  psychic,
  radiant,
  thunder,
};
