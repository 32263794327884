import { physical } from "./criteffects/physical";
import { magic } from "./criteffects/magic";
import { rollTypes } from "./criteffects/rollTypes";
export const CritEffects = {
  physical,
  magic,
};
export const injuries = [
  {
    val: 3,
    minor: {
      name: "Injured leg!",
      description: "The creature’s movement speed is reduced by 10 ft.",
    },
    major: {
      name: "Crippled leg!",
      description:
        "The creature’s movement speed is reduced by 10 feet and it has disadvantage on Dexterity saving throws.",
    },
  },
  {
    val: 8,
    minor: {
      name: "Injured arm!",
      description:
        "Randomly determine one of the creature’s arms. That arm cannot be used to hold a shield and the creature has disadvantage on any rolls involving the use of that arm.",
    },
    major: {
      name: "Crippled arm!",
      description:
        "Randomly determine one of the creature’s arms. That arm cannot be used to hold an item and any ability check requiring that arm automatically fails or has disadvantage (DM’s choice).",
    },
  },
  {
    val: 11,
    minor: {
      name: "Multiple injuries!",
      description:
        "The creature’s maximum hit points are reduced by an amount equivalent to half of the damage dealt by the attack.",
    },
    major: {
      name: "Severely wounded!",
      description:
        "The creature’s maximum hit points are reduced by an amount equivalent to the damage dealt by the attack.",
    },
  },
  {
    val: 16,
    minor: {
      name: "Badly beaten!",
      description:
        "The creature has disadvantage on Constitution saving throws.",
    },
    major: {
      name: "Edge of death!",
      description:
        "The creature has disadvantage on Constitution and death saving throws",
    },
  },
  {
    val: 19,
    minor: {
      name: "Ringing blow!",
      description:
        "The creature is stunned until the end of its next turn and deafened until it completes a the recuperate downtime activity",
    },
    major: { name: "My eyes!", description: "The creature is blinded." },
  },
  {
    val: 20,
    minor: {
      name: "Blow to the head!",
      description: "The creature is unconscious for 2d12 hours",
    },
    major: { name: "Decapitated! ", description: "The creature is dead." },
  },
];
export const getEffectFromType = (effectType, subtype) => {
  return CritEffects[effectType][subtype];
};

export const getIndexFromRoll = (rollVal) => {
  if (rollVal <= 1) {
    return 0;
  }
  if (rollVal <= 3) {
    return 1;
  }
  if (rollVal <= 6) {
    return 2;
  }
  if (rollVal <= 8) {
    return 3;
  }
  if (rollVal <= 11) {
    return 4;
  }
  if (rollVal <= 13) {
    return 5;
  }
  if (rollVal <= 16) {
    return 6;
  }
  if (rollVal <= 18) {
    return 7;
  }
  if (rollVal <= 19) {
    return 8;
  }
  if (rollVal <= 20) {
    return 9;
  }
  return 10;
};
export const getInjuryFromRoll = (rolltype, roll) => {
  const injury = injuries
    .filter((el) => el.val <= roll)
    .reduce((prev, current) => {
      return prev.val > current.val ? prev : current;
    });
  if (rolltype === rollTypes.majorInjury) {
    return injury.major;
  } else {
    return injury.minor;
  }
};
